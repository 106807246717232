<template>
  <v-card class="pa-0">
    <v-toolbar min-width="100%" dark color="primary">
      <v-card-title>All Template</v-card-title>
      <v-spacer> </v-spacer>

      <v-dialog v-model="dialogs" persistent max-width="50%">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" v-bind="attrs" v-on="on"> Add New </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-h5"> New Template </v-card-title>

          <v-card-text>
            <v-text-field
              v-model="title"
              :rules="$requiredRules"
              label="Title*"
            ></v-text-field>

            <v-textarea
              rows="5"
              v-model="content"
              :rules="$requiredRules"
              label="Content*"
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="greens darken-1" @click="save"> Save </v-btn>
            <v-btn color=" darken-1" @click="cancelDialog"> cancel </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-col cols="12" md="8" lg="12" style="margin-top: 10px">
      <v-row style="margin-top: 10px; border: 1px solid grey">
        <v-col cols="3" class="text-right" style="margin-right: 20px">
          Filter by date
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="start_date"
            label="Start Date"
            type="date"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="end_date"
            label="End Date"
            type="date"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <DataTableSSR
      apiEndPoint="template/"
      :headers="headers"
      :instantLoad="true"
      :key="r"
      :queryParams="{
        start_date: start_date,
        end_date: end_date,
      }"
    >
      <template #[`item.show`]="{ item }">
        <v-dialog v-model="item.dialog" width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-input v-bind="attrs" v-on="on" id="title" color="black">
              {{ item.title.substring(0, 20) + ".." }}
            </v-input>
          </template>
          <v-card>
            <v-card-title>
              <v-row>
                <v-col cols="12" md="8" lg="11">
                  <span class="text-h5">Template Details</span>
                </v-col>
                <v-col cols="12" md="4" lg="1" ml="14" pl="2">
                  <v-btn icon @click="item.dialog = false">
                    <v-icon large color="red lighten-2"
                      >mdi-close-circle-outline</v-icon
                    >
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>

            <v-card-text>
              <v-card>
                <v-toolbar color="primary" dark dense flat>
                  <v-toolbar-title class="text-body-2">
                    {{ item.title }}
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  {{ item.content }}
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>

      <template #[`item.created_at`]="{ item }">
        {{ new Date(item.created_at).toLocaleString() }}
      </template>

      <template #[`item.action`]="{ item }">
        <v-dialog max-width="700px" v-model="item.dialogNewFee">
          <template v-slot:activator="{ attrs, on }">
            <v-row>
              <v-btn
                v-bind="attrs"
                :color="item.is_active ? 'primary' : 'warning'"
                :disabled="!item.is_active"
                class="ma-4"
                v-on="on"
                outlined
              >
                Edit
              </v-btn>

              <v-switch
                depressed
                :input-value="item.is_active"
                @change="toggleActive(item)"
              ></v-switch>
              
            </v-row>
          </template>
          <update-template
            :item="item"
            @closeDialog="item.dialogNewFee = false"
            @reFreshData="reFreshData"

          >
          
          </update-template>
        </v-dialog>
      </template>
    </DataTableSSR>
  </v-card>
</template>

<script>
import DataTableSSR from "../../../components/global/DataTableSSR.vue";
import UpdateTemplate from "./UpdateTemplate.vue";

export default {
  components: { DataTableSSR, UpdateTemplate },
  props: ["item", "class_id"],
  emits: ["closeDialog","reFreshData"],
  data: () => ({
    start_date: null,
    end_date: null,
    r:0,
    headers: [
      {
        text: "Title",
        value: "show",

        hover: "yellow",
      },
      {
        text: "Created ",
        value: "created_at",
      },
      {
        text: "Action",
        value: "action",
      },
    ],

    dialogNewFee: false,
    templateData:[],
    template: {
      title: "",
      content: "",
    },
    is_active: false,
    dialog: false,
    closedialog: false,
    dialogs: false,
  }),

  // async created() {
  //   await this.loadtemplate();
  // },
  mounted() {
    this.loadtemplateData();

    this.loadtemplate();

  },
  computed: {
    rowClasses() {
      return (item) => ({
        "active-row": item.is_active,
        "inactive-row": !item.is_active,
      });
    },
  },
  methods: {
    loadtemplate() {
      return this.$api
        .get(`/template/${this.item.id}`)
        .then((response) => {
          this.template = response.data.results;
          this.$emit("closeDialog");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadtemplateData() {
      return this.$api
        .get(`/template/`)
        .then((response) => {
          this.templateData = response.data.results;
          
        }).then(()=>{this.r+=1;this.resetValues()})
        
    },
    reFreshData(){
      this.loadtemplateData();
    },

    status() {
      return this.$api
        .put(`/template/${this.item.id}/`, this.template)
        .then((res) => (this.template = res.data));
    },
    cancel() {
      this.closedialog = false;
    },
    save() {
      const data = {
        title: this.title,
        content: this.content,
      };
      this.$api.post("/template/", data).then(()=>{this.r+=1;this.resetValues()}).catch((err) => {
        console.log(err);
      });

      this.dialogs = false;
    },
    cancel_dialog() {
      this.dialogs = false;
    },
    resetValues() {
      this.title = null;
      this.content = null;
    },
    closeDialogNewFee() {
      this.closeDialogNewFee = false;
    },
    update() {
      this.$api
        .put(`/template/${this.item.id}/`, this.template)
        .then((res) => (this.template = res.data));

      // .then((r) => console.log(r));
    },
    cancelDialog() {
      this.dialogs = false;
    },
    toggleActive(item) {
      const newActiveState = !item.is_active;
      this.$api
        .put(`/template/${item.id}/`, {
          is_active: newActiveState,
          title: item.title,
          content: item.content,
        })
        .then((response) => {
          item.is_active = response.data.is_active;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style>
.active-row {
  background-color: green;
}

.inactive-row {
  background-color: red;
}
</style>
